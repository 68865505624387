import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, ContentCard, Form, ToggleContainer } from '@weezevent/nacre';
import Styles from '../../../../styles/components/invitations/attendee-creation-drawer.module.css';
import { AttendeeInformationTable } from '../attendeeTable';
import CardStyle from '../../../../styles/components/invitations/edit-attendee-card.module.css';
import { emptyGuest } from '../state';
import { emailValidation } from '../../../../tooling';

/**
 * Manage attendees with form, not tab. Usefull when you do not know all informations of attendees.
 * @param formAttendee - object - form of the first attendee
 * @param onChangeAttendee - func - on change attendee
 * @param quantity - number - quantity of attendee wanted
 * @param onChangeQuantity - func - inc or desc quantity
 */
function AttendeeInformationForm({ state, formAttendee, onChangeAttendee, quantity, onChangeQuantity, hasPartnerForm, customFields, language }) {
    const { t } = useTranslation();

    const valid_mail = React.useMemo(() => {
        if (!formAttendee?.email.length) {
            return true;
        }
        return emailValidation(formAttendee.email);
    }, [state, formAttendee, emailValidation]);

    return (
        <Form>
            <Form.Label>{t('partners.invitation.attendees_quickadd.quantity')}</Form.Label>
            <Form.CounterLabel name="quantity" onChange={(event, value) => onChangeQuantity(value)} step={1} value={quantity} />
            <Form.FieldBlock
                label={t('partners.invitation.creation.columns.header.first_name')}
                name="firstname"
                placeholder={t('partners.invitation.table.side_menu.attendee.no_named')}
                onChange={(e, d) => {
                    onChangeAttendee('first_name', d.value);
                }}
                value={formAttendee ? formAttendee.first_name : ''}
                autoComplete="off"
            />
            <Form.FieldBlock
                label={t('partners.invitation.creation.columns.header.last_name')}
                name="lastname"
                placeholder={t('partners.invitation.table.side_menu.attendee.no_named')}
                onChange={(e, d) => onChangeAttendee('last_name', d.value)}
                value={formAttendee ? formAttendee.last_name : ''}
                autoComplete="off"
            />
            <Form.FieldBlock
                label={t('partners.invitation.creation.columns.header.email')}
                name="mail"
                placeholder={t('partners.invitation.table.side_menu.attendee.no_named')}
                onChange={(e, d) => onChangeAttendee('email', d.value)}
                value={formAttendee ? formAttendee.email : ''}
                autoComplete="off"
                errors={!valid_mail}
            />
            <Form.FieldBlock
                label={t('partners.invitation.creation.columns.header.enterprise')}
                name="enterprise"
                placeholder={t('partners.invitation.table.side_menu.attendee.no_named')}
                onChange={(e, d) => {
                    let form = formAttendee ? formAttendee.form : {};
                    form['ws_9'] = d.value;
                    onChangeAttendee('form', form);
                }}
                value={formAttendee ? formAttendee.form['ws_9'] : ''}
                autoComplete="off"
                required={false}
            />
            {hasPartnerForm && <AttendeeCustomInformationForm customFields={customFields} language={language} onChangeAttendee={onChangeAttendee} formAttendee={formAttendee} />}
        </Form>
    );
}
AttendeeInformationForm.propTypes = {
    formAttendee: PropTypes.array,
    quantity: PropTypes.number,
    onChangeQuantity: PropTypes.func,
    onChangeAttendee: PropTypes.func
};

/**
 * Display attendee information card.
 *
 * @param guests - array - array of guests
 * @param onChangeGuests - func - modify guest
 * @param noNameToggle - bool - boolean to know if you want manage attendees into a table or a form
 * @param onChangeNoNameToggle - func - modify toggle
 * @param quantity - quantity - quantity of attendees
 * @param onChangeQuantity - modify quantity
 * @param partner - partner object
 */
export function GuestInformation({
    state,
    guests,
    partnerForm,
    hasPartnerForm,
    onChangeGuests,
    noNameToggle,
    onChangeNoNameToggle,
    quantity,
    onChangeQuantity,
    partner,
    language
}) {
    const { t } = useTranslation();

    const onChangeGuest = React.useCallback(
        (index, field, value, anonymousMode = false) => {
            let newGuests = [...guests];
            if (!newGuests[index]) {
                newGuests[index] = emptyGuest();
            }
            // if anonymous mode, update field for all guests
            if (anonymousMode) {
                newGuests = newGuests.map((guest) => {
                    guest[field] = value;
                    return guest;
                });
            } else {
                newGuests[index][field] = value;
            }

            // Delete line if all input are empty
            if (
                value === '' ||
                (!hasPartnerForm && value instanceof Object && value['ws_9'] === '') ||
                (hasPartnerForm && value instanceof Object && Object.values(value).every((v) => v === ''))
            ) {
                // check if each value in each input is "" or undefined (because empty)
                if (
                    (newGuests[index]['first_name'] === '' || !newGuests[index]['first_name']) &&
                    (newGuests[index]['last_name'] === '' || !newGuests[index]['last_name']) &&
                    (newGuests[index]['email'] === '' || !newGuests[index]['email']) &&
                    (newGuests[index]['form'] === {} || newGuests[index]['form']['ws_9'] === '' || !newGuests[index]['form']['ws_9'])
                ) {
                    newGuests.splice(index, 1);
                }
            }

            onChangeGuests(newGuests);
        },
        [guests, onChangeGuests, emptyGuest, hasPartnerForm]
    );

    const onPasteAttendees = React.useCallback(
        (event) => {
            event.preventDefault();
            let clip = event.clipboardData.getData('Text');
            let row = clip.split('\n');
            let rows = [];
            let tab_name = [];

            if (hasPartnerForm) {
                tab_name = partnerForm.map((value) => {
                    return value.form_field.label;
                });
            } else {
                tab_name = ['first_name', 'last_name', 'email', 'enterprise'];
            }

            let [paste_name, paste_index] = event.target.id.split('-');
            paste_index = parseInt(paste_index);

            row.forEach((r, row_number) => {
                if (!r || !r.trim() || !r.replace(/\t/g, '').trim()) {
                    return;
                }
                // get cell index
                let index = tab_name.findIndex((name) => name === paste_name);
                let existingData = [];
                // check if row already exist
                if (guests[parseInt(paste_index + row_number)] !== undefined && !hasPartnerForm) {
                    // get actual data from existing row
                    existingData = Object.values(guests[paste_index + row_number]);
                    existingData[3] = existingData[3].ws_9;
                }

                // copied data in array
                const pasted_data = r.split('\t');
                // for the moment max accepted length is 4
                const pasted_length = !hasPartnerForm ? (pasted_data.length <= 4 ? pasted_data.length : 4) : tab_name.length;

                for (let i = 0; i < pasted_length; i++) {
                    // replace existing values with pasted values
                    existingData[index + i] = pasted_data[i];
                }
                rows.push(existingData);
            });

            let newGuests = [...guests];
            for (let i = 0; i < rows.length; i++) {
                if (hasPartnerForm) {
                    newGuests[i + paste_index] = {
                        ...emptyGuest(),
                        first_name: '',
                        last_name: '',
                        email: '',
                        form: {}
                    };

                    partnerForm.forEach((v, index) => {
                        let key = v.form_field.weez_id;
                        newGuests[i + paste_index].form[key] = rows[i][index];
                    });
                } else {
                    const [first_name = '', last_name = '', email = '', ws_9 = ''] = rows[i];
                    newGuests[i + paste_index] = {
                        ...emptyGuest(),
                        first_name: first_name?.trim(),
                        last_name: last_name?.trim(),
                        email: email?.trim(),
                        form: {
                            ws_9: ws_9?.trim()
                        }
                    };
                }
            }

            onChangeGuests(newGuests);
        },
        [onChangeGuests, guests, partnerForm, hasPartnerForm]
    );

    return (
        <Card className={CardStyle['container']}>
            <Card.Header>
                <Card.Title title={t('partners.invitation.table.side_menu.information.title')} />
            </Card.Header>
            <ContentCard>
                {partner['allow_no_named'] && (
                    <ToggleContainer
                        mini
                        className={Styles['toggle-block-container']}
                        toggle={noNameToggle}
                        title={t('partners.invitation.table.side_menu.information.no_name_toggle')}
                        onToggle={() => {
                            onChangeNoNameToggle(!noNameToggle);
                        }}
                    />
                )}

                {noNameToggle ? (
                    <AttendeeInformationForm
                        state={state}
                        language={language}
                        formAttendee={guests[0]}
                        hasPartnerForm={hasPartnerForm}
                        customFields={partnerForm}
                        onChangeAttendee={(field, value) => onChangeGuest(0, field, value, true)}
                        quantity={quantity}
                        onChangeQuantity={onChangeQuantity}
                    />
                ) : (
                    <AttendeeInformationTable
                        state={state}
                        language={language}
                        hasPartnerForm={hasPartnerForm}
                        customFields={partnerForm}
                        formAttendee={[...guests, emptyGuest(partnerForm)]}
                        onChangeAttendee={onChangeGuest}
                        onPasteAttendees={onPasteAttendees}
                        allowNoNamed={partner.allow_no_named}
                    />
                )}
            </ContentCard>
        </Card>
    );
}
GuestInformation.propTypes = {
    noNameToggle: PropTypes.bool,
    guests: PropTypes.array,
    quantity: PropTypes.number,
    onChangeNoNameToggle: PropTypes.func,
    onChangeQuantity: PropTypes.func,
    onChangeGuests: PropTypes.func
};

function AttendeeCustomInformationForm({ customFields, language, formAttendee, onChangeAttendee }) {
    const { t } = useTranslation();
    const defaultFields = ['ws_2', 'ws_3', 'ws_5', 'ws_9'];

    const handleChange = React.useCallback(
        (value, key) => {
            let form = formAttendee ? { ...formAttendee.form } : {};
            form[key] = value;
            onChangeAttendee('form', form);
        },
        [formAttendee, onChangeAttendee]
    );

    if (!customFields.length) {
        return null;
    }
    return customFields
        .filter((fd) => !defaultFields.includes(fd.form_field.weez_id))
        .map((field) => {
            const key = field.form_field.weez_id.replace('-', '_');
            return (
                <Form.FieldBlock
                    key={field.weez_field_id}
                    label={field.form_field.translations.label[language]}
                    name="mail"
                    placeholder={t('partners.invitation.table.side_menu.attendee.no_named')}
                    onChange={(e, d) => {
                        handleChange(d.value, key);
                    }}
                    value={formAttendee ? formAttendee.form[key] : ''}
                    autoComplete="off"
                />
            );
        });
}
