import React from 'react';
import PropTypes from 'prop-types';

import { DropdownLight } from '@weezevent/nacre';
import { useTranslation } from 'react-i18next';
import Style from './dropdownPageSize.module.css';

export default function DropdownPageSize({
    pageSize,
    orderMode = false,
    onChange = () => {},
    onChangeCurrentpage = () => {},
    translation_key = 'ticket.manager.attendees.pageSize'
}) {
    const { t } = useTranslation();

    let pageSizes = React.useMemo(() => {
        const pageSizesArray = orderMode ? [50, 100] : [50, 100, 250, 500, 1000];
        const pageSizeToOptions = (value) => ({ key: value, label: `${t(translation_key, { count: value })}`, value: value });
        return pageSizesArray.map(pageSizeToOptions);
    }, []);

    return (
        <DropdownLight
            position={'bottom-end'}
            className={Style['dropdown-pagesize']}
            value={pageSize}
            onChange={(value) => {
                onChange(+value);
                onChangeCurrentpage(0);
            }}
            items={pageSizes}
        />
    );
}

DropdownPageSize.propTypes = {
    onChange: PropTypes.func,
    onChangeCurrentPage: PropTypes.func,
    pageSize: PropTypes.number.isRequired,
    orderMode: PropTypes.bool
};
