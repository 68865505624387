export function emailValidation(email) {
    const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return re.test(email);
}

export function capitalizeFirstLetter(string) {
    if (!string || string.length === 0) {
        return '';
    }

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
