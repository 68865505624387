import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Statistics, Icon } from '@weezevent/nacre';
import Styles from '../../styles/components/stats/stats-card.module.css';
import StatsSideBar from './statsSideBar';
import * as Sentry from '@sentry/browser';

function StatisticsMoreLabel({ title, onClick }) {
    const { t } = useTranslation();

    return (
        <div className={Styles['statistics-more-label-container']} onClick={onClick}>
            <span className={Styles['statistics-more-label']}>{title || t('partners.common.moreDetails')}</span>
            <Icon name={'chevron-right'} size={'small'} />
        </div>
    );
}

export function StatsCardFetch({ partnerId, attendeeInProgress = 0, batchStatsModel, googleAnalytics }) {
    const [stats, , sync, error] = batchStatsModel.useApiModel({
        id: partnerId,
        partnerId,
        getAll: false,
        onError: (e) => {
            console.error(e);
            Sentry.captureException(e);
        }
    });

    const [previousAttendeeInProgress, setPreviousAttendeeInProgress] = React.useState(attendeeInProgress);

    React.useEffect(() => {
        if (previousAttendeeInProgress !== 0 && attendeeInProgress === 0) {
            sync();
        }
        setPreviousAttendeeInProgress(attendeeInProgress);
    }, [attendeeInProgress, sync, setPreviousAttendeeInProgress, previousAttendeeInProgress]);

    if (error) {
        return null;
    }
    return <StatsCard stats={stats} attendeeInProgress={attendeeInProgress} googleAnalytics={googleAnalytics} />;
}
StatsCardFetch.propTypes = {
    partnerId: PropTypes.string,
    attendeeInProgress: PropTypes.number,
    batchStatsModel: PropTypes.func,
    googleAnalytics: PropTypes.object
};

export default function StatsCard({ stats, attendeeInProgress, googleAnalytics }) {
    const { t } = useTranslation();
    const [showQuotaSB, setQuotaSB] = React.useState(false);

    const sortInventories = (a, b) => {
        const i1_start_date = a.event.start_date;
        const i2_start_date = b.event.start_date;

        if (!i1_start_date && !i2_start_date) {
            return new Date(b.event.start_date) - new Date(a.event.start_date);
        }
        if (!i2_start_date) {
            return 1;
        }
        if (!i1_start_date) {
            return -1;
        }
        return new Date(i2_start_date) - new Date(i1_start_date);
    };

    const futureInventories = React.useMemo(() => {
        if (!stats) {
            return [];
        }

        const realInventories = stats.inventories
            .filter((inventory) => (inventory.event && !inventory.virtual ? (inventory.event.end_date ? inventory.event.end_date > new Date().toISOString() : true) : false))
            .sort((i_1, i_2) => {
                return sortInventories(i_2, i_1);
            });

        const virtualInventories = stats.inventories
            .filter((inventory) => (inventory.event && inventory.virtual ? (inventory.event.end_date ? inventory.event.end_date > new Date().toISOString() : true) : false))
            .sort((i_1, i_2) => {
                return sortInventories(i_2, i_1);
            });

        return realInventories.concat(virtualInventories);
    }, [stats]);

    const currentInventory = React.useMemo(() => {
        if (!futureInventories.length) {
            return null;
        }
        return futureInventories[0];
    }, [futureInventories]);

    const labelDateNextEvent = React.useMemo(() => {
        const arrayDate = [];
        if (currentInventory?.event.start_date) {
            arrayDate.push(
                new Date(currentInventory.event.start_date).toLocaleString([], {
                    timeStyle: 'long',
                    dateStyle: 'medium',
                    timeZone: currentInventory.event.timezone
                })
            );
        }

        if (currentInventory?.event.end_date) {
            arrayDate.push(
                new Date(currentInventory.event.end_date).toLocaleString([], {
                    timeStyle: 'long',
                    dateStyle: 'medium',
                    timeZone: currentInventory.event.timezone
                })
            );
        }
        return arrayDate.join(' - ');
    }, [currentInventory]);

    const onClickAway = React.useCallback(() => {
        setQuotaSB(false);
    }, [setQuotaSB]);

    const onClick = React.useCallback(() => {
        setQuotaSB(true);
        googleAnalytics?.event({
            category: 'common',
            action: 'Click +details (home)',
            label: 'Display batches statistics'
        });
    }, [setQuotaSB, googleAnalytics]);

    //Todo: add loader
    if (!stats) {
        return null;
    }

    return (
        <>
            <StatsSideBar showSideBar={showQuotaSB} datas={stats.inventories} onClickAway={onClickAway} />
            <Statistics.Group className={Styles['statistics__container']}>
                <Statistics
                    subtitle={t('partners.invitation.stats.taken')}
                    stat={<span style={{ fontSize: '16px', color: 'rgb(var(--primary-color))' }}>{stats?.partner?.taken + attendeeInProgress}</span>}
                    footer={<StatisticsMoreLabel onClick={onClick} />}
                />
                {currentInventory && (
                    <Statistics
                        className={Styles['statistics__next-event']}
                        subtitle={t('partners.invitation.stats.next_event', {
                            name: currentInventory?.name,
                            date: labelDateNextEvent
                        })}
                        stat={
                            <span style={{ fontSize: '16px', color: 'rgb(var(--primary-color))' }}>
                                {t('partners.invitation.stats.remaining_distribute', { count: currentInventory?.available })}
                            </span>
                        }
                        footer={<StatisticsMoreLabel onClick={onClick} />}
                        size={'medium'}
                    />
                )}
                {futureInventories.length > 1 && (
                    <Statistics
                        subtitle={t('partners.invitation.stats.available')}
                        stat={<span style={{ fontSize: '16px', color: 'rgb(var(--primary-color))' }}>{stats?.partner?.available - attendeeInProgress}</span>}
                        footer={<StatisticsMoreLabel onClick={onClick} />}
                    />
                )}
            </Statistics.Group>
        </>
    );
}

StatsCard.propTypes = {
    stats: PropTypes.object,
    partnerId: PropTypes.string,
    onLoad: PropTypes.func,
    attendeeInProgress: PropTypes.number,
    googleAnalytics: PropTypes.object
};
