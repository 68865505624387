// TODO Rename GuestTable and move in GuestInformation folder.

import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Input, Message } from '@weezevent/nacre';
import Styles from '../../../styles/components/invitations/attendee-creation-drawer.module.css';
import { emailValidation } from '../../../tooling';

/**
 * Row of attendee in the table of information
 * @param index : Row number
 * @param attendee : attendee filled
 * @param onChangeAttendee: on fill attendee
 */
function AttendeeInformationRow({ index, state, attendee, onChangeAttendee, onPaste, allowNoNamed }) {
    const { t } = useTranslation();

    const valid_mail = React.useMemo(() => {
        if (!attendee?.email.length) {
            return true;
        }
        return emailValidation(attendee.email);
    }, [state, attendee, emailValidation]);

    return (
        <tr>
            <td className={Styles['table-cell-index']}>{index + 1}</td>
            <td className={Styles['table-cell']}>
                <Input
                    // if !allowNoNamed, first_name is empty and we are in a line who start created a guest
                    errors={!allowNoNamed && attendee.first_name === '' && index < state.guests.length}
                    className={Styles['cell-input']}
                    placeholder={t('partners.invitation.creation.columns.header.first_name')}
                    onPaste={onPaste}
                    maxLength={150}
                    value={attendee.first_name}
                    onChange={({ target: { value } }) => {
                        onChangeAttendee(index, 'first_name', value);
                    }}
                    id={`first_name-${index}`}
                    //https://stackoverflow.com/a/34998771
                    autoComplete="none"
                />
            </td>
            <td className={Styles['table-cell']}>
                <Input
                    // if !allowNoNamed, last_name is empty and we are in a line who start created a guest
                    errors={!allowNoNamed && attendee.last_name === '' && index < state.guests.length}
                    className={Styles['cell-input']}
                    placeholder={t('partners.invitation.creation.columns.header.last_name')}
                    onPaste={onPaste}
                    maxLength={120}
                    value={attendee.last_name}
                    onChange={({ target: { value } }) => {
                        onChangeAttendee(index, 'last_name', value);
                    }}
                    id={`last_name-${index}`}
                    autoComplete="none"
                />
            </td>
            <td className={Styles['table-cell']}>
                <Input
                    errors={!valid_mail}
                    className={Styles['cell-input']}
                    placeholder={t('partners.invitation.creation.columns.header.email')}
                    onPaste={onPaste}
                    maxLength={250}
                    value={attendee.email}
                    onChange={({ target: { value } }) => {
                        onChangeAttendee(index, 'email', value);
                    }}
                    id={`email-${index}`}
                    autoComplete="none"
                />
            </td>
            <td className={Styles['table-cell']}>
                <Input
                    className={Styles['cell-input']}
                    placeholder={t('partners.invitation.creation.columns.header.enterprise')}
                    onPaste={onPaste}
                    maxLength={150}
                    value={attendee.form['ws_9'] || ''}
                    onChange={({ target: { value } }) => {
                        const { form = {} } = attendee;
                        form['ws_9'] = value;
                        onChangeAttendee(index, 'form', form);
                    }}
                    id={`enterprise-${index}`}
                    autoComplete="none"
                />
            </td>
        </tr>
    );
}
AttendeeInformationRow.propTypes = {
    index: PropTypes.number,
    attendee: PropTypes.object,
    onChangeAttendee: PropTypes.func
};

// const MemoFormItem = React.memo(AttendeeInformationRow);

/**
 * Table of attendees informations.
 * The aim is to take as param the formAttendee, convert it into
 * fake array of attendee (because on the X lines display we can only fill few of them) and save
 * attendees into parent component.
 *
 * @param formAttendee : array of attendees already filled
 * @param onChangeAttendees: on fill attendees
 */
export function AttendeeInformationTable({ state, formAttendee = [], customFields = [], onChangeAttendee, onPasteAttendees, allowNoNamed, hasPartnerForm, language }) {
    const { t } = useTranslation();

    return (
        <div>
            <Message visible={true} title={t('partners.invitation.table.side_menu.information.tooltip_title')} type={'default'}>
                {t('partners.invitation.table.side_menu.information.tooltip')}
            </Message>
            <div className={Styles['table-container-block']}>
                <table className={Styles['table-container']} cellSpacing={0}>
                    <tr>
                        <th />
                        <AttendeeTableHeader hasPartnerForm={hasPartnerForm} customFields={customFields} language={language} />
                    </tr>
                    {formAttendee.map((attendee, index) =>
                        !hasPartnerForm ? (
                            <AttendeeInformationRow
                                key={index}
                                index={index}
                                state={state}
                                attendee={attendee}
                                onChangeAttendee={onChangeAttendee}
                                onPaste={onPasteAttendees}
                                allowNoNamed={allowNoNamed}
                            />
                        ) : (
                            <tr>
                                <td className={Styles['table-cell-index']}>{index + 1}</td>
                                {customFields.map((field) => {
                                    return (
                                        <AttendeeFormInput
                                            key={field.weez_id}
                                            field={field}
                                            attendee={attendee}
                                            onPaste={onPasteAttendees}
                                            index={index}
                                            allowNoNamed={allowNoNamed}
                                            onChangeAttendee={onChangeAttendee}
                                            language={language}
                                        />
                                    );
                                })}
                            </tr>
                        )
                    )}
                </table>
                <div className={Styles['attendee-counter']}>{t('partners.invitation.table.side_menu.information.attendee_count', { count: formAttendee.length - 1 })}</div>
            </div>
        </div>
    );
}
AttendeeInformationTable.propTypes = {
    formAttendee: PropTypes.array,
    onChangeAttendee: PropTypes.func
};

function AttendeeFormInput({ field, attendee, onPaste, index, language, allowNoNamed, onChangeAttendee }) {
    const fieldId = field.form_field.weez_id;
    const fieldName = field.form_field.label;

    const valid_mail = React.useMemo(() => {
        if (!('ws_5' in attendee.form) || !attendee.form['ws_5'] || attendee.form['ws_5'] === '') {
            return true;
        }
        return emailValidation(attendee.form['ws_5']);
    }, [attendee.form, emailValidation, onChangeAttendee]);

    const handleChange = React.useCallback(
        (value, id) => {
            const { form = {} } = attendee;
            form[id] = value;
            onChangeAttendee(index, 'form', form);
        },
        [attendee, onChangeAttendee, index]
    );

    const inputErrors = React.useMemo(() => {
        if (fieldName === 'Email') {
            return !valid_mail;
        }
        if (fieldName === 'First name' || fieldName === 'Last name') {
            return (!allowNoNamed && (!('ws_2' in attendee.form) || attendee.form['ws_2'] !== '')) || !('ws_3' in attendee.form || attendee.form['ws_3'] !== '');
        }
        return null;
    }, [attendee, valid_mail, allowNoNamed, fieldName]);

    return (
        <td className={Styles['table-cell']}>
            <Input
                errors={inputErrors}
                className={Styles['cell-input']}
                placeholder={field.form_field.translations.label[language]}
                onPaste={onPaste}
                maxLength={fieldName === 'Email' ? 250 : 150}
                value={attendee.form[fieldId] || ''}
                onChange={({ target: { value } }) => {
                    handleChange(value, fieldId);
                }}
                id={`${fieldName}-${index}`}
                autoComplete="none"
            />
        </td>
    );
}

function AttendeeTableHeader({ hasPartnerForm, customFields, language }) {
    const { t } = useTranslation();

    if (!hasPartnerForm) {
        return (
            <>
                <th>{t('partners.invitation.creation.columns.header.first_name')}</th>
                <th>{t('partners.invitation.creation.columns.header.last_name')}</th>
                <th className={Styles['cell-email']}>{t('partners.invitation.creation.columns.header.email')}</th>
                <th>{t('partners.invitation.creation.columns.header.enterprise')}</th>
            </>
        );
    }

    return customFields.map((field) => (
        <th className={Styles['custom-form-table-header']} key={field.id}>
            {field.form_field.translations.label[language]}
        </th>
    ));
}
