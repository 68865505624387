import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Table, Dropdown, Tooltip, Icon, InfoText } from '@weezevent/nacre';
import { ReactComponent as Seat } from './../../styles/icons/siege-available.svg';

import DisplayStatus from './status';
import { InfoTextTooltip } from '../ticketing/infotext-tooltip/infoTextTooltip';

import Styles from '../../styles/components/invitations/attendees-list.module.css';
import { isActionDisabled } from './list';

function SubLabelSeating({ booking }) {
    const { t } = useTranslation();

    //Check free seating
    if (!booking.seat_uuid && booking.area_zone) {
        return <span>{t('partners.common.seating.freeSeating')}</span>;
    }

    return (
        <span>
            <span className={`${Styles['bold']} ${Styles['inline']}`}>{t('partners.common.seating.shortLabelSeat')}</span>
            {`${booking.seat_seat} • `}
            <span className={`${Styles['bold']} ${Styles['inline']}`}>{t('partners.common.seating.shortLabelRow')}</span>
            {`${booking.seat_row} • `}
            <span className={`${Styles['bold']} ${Styles['inline']}`}>{t('partners.common.seating.shortLabelAccess')}</span>
            {booking.seat_access}
        </span>
    );
}

SubLabelSeating.propTypes = {
    booking: PropTypes.object.isRequired
};

function AttendeeSeating({ booking, mode = 'default' }) {
    const { t } = useTranslation();

    const sizingIconSeat = React.useMemo(() => {
        return {
            width: mode === 'mini' ? '15px' : '12px',
            height: mode === 'mini' ? '14px' : '11px'
        };
    }, [mode]);

    if (!booking) {
        return <InfoTextTooltip title={t('partners.common.seating.unseatedTicket')} />;
    }

    const titleTooltipSeating = (booking) => {
        //Check free seating
        if (!booking.seat_uuid && booking.area_zone) {
            return t('partners.invitation.table.tooltipSeating.freeSeating', { category: booking.rate_category_name, zone: booking.area_zone_name });
        }

        return t('partners.invitation.table.tooltipSeating.seating', {
            category: booking.rate_category_name,
            zone: booking.seat_zone_name,
            seat: booking.seat_seat,
            row: booking.seat_row,
            access: booking.seat_access
        });
    };

    return (
        <InfoText subtitle={mode !== 'mini' ? <SubLabelSeating booking={booking} /> : null}>
            <div className={Styles['seating-info']}>
                <style>{`
                    svg.seat-category {
                        width: ${sizingIconSeat.width};
                        height: ${sizingIconSeat.height};
                        margin-right: 3px;
                   }

                    svg.seat-category-${booking.rate_category_color.substring(1, booking.rate_category_color.length)} path {
                        fill: ${booking.rate_category_color};
                   }
                `}</style>
                <Tooltip triggerOnHover title={<span style={{ whiteSpace: 'break-spaces' }}>{titleTooltipSeating(booking)}</span>} margin={false} position={'top-start'}>
                    <Seat className={`seat-category ${'seat-category-' + booking.rate_category_color.substring(1, booking.rate_category_color.length)}`} />
                </Tooltip>
                {mode !== 'mini' && <span>{!booking.seat_uuid && booking.area_zone ? booking.area_zone_name : booking.seat_zone_name}</span>}
            </div>
        </InfoText>
    );
}

AttendeeSeating.propTypes = {
    booking: PropTypes.object.isRequired,
    mode: PropTypes.string
};

export default function AttendeeRow({
    attendee,
    partner,
    onSelected,
    selected = false,
    onSendMail,
    setShowDeleteModal,
    onClick,
    children,
    googleAnalytics,
    organizationId,
    setSelectedAttendees,
    onDownloadTicket
}) {
    const { t, i18n } = useTranslation();

    const lastMailTracker = React.useMemo(() => {
        if (!attendee) {
            return null;
        }

        if (!attendee.mail_trackers.length) {
            return null;
        }

        return attendee.mail_trackers.sort((a, b) => new Date(b.created) - new Date(a.created))[0];
    }, [attendee]);

    const sendMailTooltip = React.useMemo(() => {
        if (!attendee.send_mail) {
            return t('partners.invitation.send_mail.disabled');
        }
        return t('partners.invitation.table.send_mail');
    }, [attendee]);

    const downloadTooltip = React.useMemo(() => {
        if (!attendee.ticket_url) {
            return t('partners.invitation.download_ticket_disabled');
        }
        return t('partners.invitation.table.download_ticket');
    }, [attendee]);

    const disabledDeletion = React.useMemo(() => {
        const ticket_deletion_conditions = partner.settings?.attendees_management?.ticket_deletion_conditions;
        if (organizationId) {
            return false;
        }
        if (!ticket_deletion_conditions) {
            return attendee.scanned;
        }
        return isActionDisabled(ticket_deletion_conditions, attendee);
    }, [organizationId, partner, attendee, isActionDisabled]);

    return (
        <Table.Tr active={selected} onSelected={(event, data) => onSelected(attendee, data.checked)} className={Styles['tr-body']}>
            <Table.Td onClick={onClick} className={`${Styles['width-x-large']} ${Styles['list__td-info-text']}`}>
                <InfoTextTooltip title={attendee.resolved_attendee_name} subtitle={attendee.email} />
            </Table.Td>
            <Table.Td onClick={onClick} className={`${Styles['width-large']} ${Styles['list__td-info-text']}`}>
                <InfoTextTooltip title={Array.isArray(attendee.form) && (attendee.form.find(({ weez_id }) => weez_id === 'ws-9')?.answer || '')} />
            </Table.Td>
            <Table.Td onClick={onClick} className={`${Styles['width-x-large']} ${Styles['list__td-info-text']}`}>
                <InfoTextTooltip
                    title={attendee.booking.inventory_name}
                    subtitle={
                        attendee.booking.event_start_date
                            ? Intl.DateTimeFormat(i18n.language, { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(attendee.booking.event_start_date))
                            : ''
                    }
                />
            </Table.Td>
            <Table.Td onClick={onClick} className={`${Styles['width-x-large']} ${Styles['list__td-info-text']}`}>
                <InfoTextTooltip title={attendee.booking.rate_name} subtitle={attendee.booking.rate_category_name} />
            </Table.Td>
            {partner.is_seated && (
                <Table.Td onClick={onClick} style={{ textAlign: 'center' }} className={`${Styles['width-mini']} ${Styles['list__td-info-text']}`}>
                    <AttendeeSeating booking={attendee.booking} mode={'mini'} />
                </Table.Td>
            )}
            <Table.Td onClick={onClick} className={Styles['width-mini']}>
                {
                    // Ugly if date of last mail tracker is < 12 oct 2020 because we do not have sync of status with pat before
                    lastMailTracker && new Date(lastMailTracker.created).getTime() < new Date(2020, 9, 12).getTime() ? (
                        attendee?.mail_tracking_count
                    ) : (
                        <DisplayStatus status={lastMailTracker ? lastMailTracker.status : null} attendee_mail={attendee.email} />
                    )
                }
            </Table.Td>
            <Table.Td className={Styles['td-scanned']}>{attendee.scanned && <Icon name="check" size="small" inverted circular />}</Table.Td>
            <Table.Td className={Styles['width-mini']}>
                <div className={Styles['container-icons-action']}>
                    <Tooltip margin={false} title={sendMailTooltip} position={'top-end'} triggerOnHover>
                        <Icon
                            name="envelope"
                            disabled={!attendee.send_mail}
                            onClick={() => {
                                onSendMail([attendee]);
                                googleAnalytics?.event({
                                    category: 'Guest list',
                                    action: 'Menu actions - Send invitation',
                                    label: 'Send invitations'
                                });
                            }}
                        />
                    </Tooltip>

                    <Tooltip margin={false} title={downloadTooltip} position={'top-end'} triggerOnHover>
                        <Icon
                            disabled={!attendee.ticket_url}
                            name="download"
                            onClick={() => {
                                onDownloadTicket([attendee]);
                                googleAnalytics?.event({
                                    category: 'Guest list',
                                    action: 'Menu actions - Download invitation',
                                    label: 'Download invitations'
                                });
                            }}
                        />
                    </Tooltip>

                    <Dropdown className={Styles['dropdown-action']}>
                        {children}
                        <Dropdown.Line />
                        <Dropdown.Item
                            deleteItem
                            disabled={disabledDeletion}
                            item={t('partners.invitation.table.delete_attendee')}
                            onClick={() => {
                                setShowDeleteModal(true);
                                setSelectedAttendees([attendee]);
                            }}
                        />
                    </Dropdown>
                </div>
            </Table.Td>
        </Table.Tr>
    );
}

AttendeeRow.propTypes = {
    attendee: PropTypes.object.isRequired,
    onSelected: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    onSendMail: PropTypes.func.isRequired,
    children: PropTypes.node,
    onDelete: PropTypes.func.isRequired,
    googleAnalytics: PropTypes.object.isRequired,
    partner: PropTypes.object.isRequired,
    disabledDeletion: PropTypes.bool.isRequired,
    setSelectedAttendees: PropTypes.func.isRequired
};
