export function getDefaultRateSelection(inventories) {
    let defaultRateSelection = {
        // Inventory and rate selected
        inventory: null,
        rate: null,

        // List of bookings fetched for this selection (Cache)
        bookings: [],
        zone: null,
        bookings_for_zone: [],
        bookings_by_id: {},
        bookings_available: [],
        isGA: false,

        first_booking: null,
        is_manual: false
    };
    let key = 'new_event';
    if (inventories.length === 1) {
        let inventory = inventories[0];
        defaultRateSelection.inventory = inventory;
        key = inventory.uuid;
    }
    defaultRateSelection.key = key;
    return { key, defaultRateSelection };
}

export function getAttendeeCreationInitialState(inventories) {
    let rateSelections = {};
    let { key, defaultRateSelection } = getDefaultRateSelection(inventories);
    rateSelections[key] = defaultRateSelection;

    return {
        anonymousMode: false,
        guests: [],
        rateSelections: rateSelections,
        bookingsUsed: {}
    };
}

export const emptyGuest = function (formKeys = null) {
    let guest = {
        first_name: '',
        last_name: '',
        email: '',
        form: {},
        bookings: {},
        key: Date.now() * 10000 + Math.floor(Math.random() * 10000)
    };
    // If a formPartner exists, form keys are parsed with a default value
    // Otherwise formKeys is an empty array which doesn't trigger anything
    if (formKeys && formKeys.length > 0) {
        formKeys.forEach((k) => {
            const key = k.form_field.weez_id;
            guest.form[key] = '';
        });
    }
    return guest;
};

export const getRateSelectionKey = ({ inventory_uuid, rate_id }) => {
    if (!inventory_uuid) {
        return 'new_event';
    }
    return rate_id ? `${inventory_uuid}-${rate_id}` : inventory_uuid;
};

function prepareBookingsToAttach(rateSelection, countAttendees, bookingsUsed) {
    let bookingsFilteredByZone = rateSelection.bookings_for_zone.filter((b) => !bookingsUsed[b.booking_uuid]);
    if (bookingsFilteredByZone[0]?.area_zone) {
        return bookingsFilteredByZone;
    }
    let indexFirstBooking = bookingsFilteredByZone.findIndex((b) => b.booking_uuid === rateSelection.first_booking.booking_uuid);
    if (indexFirstBooking + countAttendees <= bookingsFilteredByZone.length) {
        return bookingsFilteredByZone.slice(indexFirstBooking, indexFirstBooking + countAttendees);
    }
    let bookingReadyToAttach = bookingsFilteredByZone.slice(indexFirstBooking, bookingsFilteredByZone.length);
    return bookingReadyToAttach.concat(bookingsFilteredByZone.slice(indexFirstBooking - (countAttendees - bookingReadyToAttach.length), indexFirstBooking));
}

function attachBookingToGuests(state, rateSelectionKey) {
    if (!state.guests.length || !state.rateSelections[rateSelectionKey]) {
        return state;
    }

    //init bookingUsed of the rateSelection
    state.bookingsUsed[rateSelectionKey] = {};

    let bookingsReadyToAttach = prepareBookingsToAttach(state.rateSelections[rateSelectionKey], state.guests.length, state.bookingsUsed[rateSelectionKey]);

    state.guests.forEach((guest, index) => {
        let booking = bookingsReadyToAttach[index];
        if (booking) {
            guest.bookings[rateSelectionKey] = booking;
            state.bookingsUsed[rateSelectionKey][booking.booking_uuid] = booking;
        } else {
            delete guest.bookings[rateSelectionKey];
        }
    });

    return { ...state };
}

function removeBookingsGuestsByRateSelection(guests, rateSelectionKey) {
    return guests.map((guest) => {
        delete guest.bookings[rateSelectionKey];

        return guest;
    });
}

function deleteRateSelection(state, { ...action }) {
    delete state.rateSelections[action.key];
    delete state.bookingsUsed[action.key];

    let stateGuestsUpdated = removeBookingsGuestsByRateSelection(state.guests, action.key);
    state.guests = [...stateGuestsUpdated];

    return { ...state };
}

export function setFirstBooking(state, rateSelectionKey) {
    let rateSelection = state.rateSelections[rateSelectionKey];

    if (!rateSelection.zone) {
        rateSelection.zone = rateSelection.bookings[0].seat_zone || rateSelection.bookings[0].area_zone;
    }

    let bookings = rateSelection.bookings.filter((booking) => (booking.seat_zone || booking.area_zone) === rateSelection.zone);
    rateSelection.first_booking = bookings[0];
    rateSelection.bookings_for_zone = bookings;

    state.rateSelections[rateSelectionKey] = rateSelection;
    state = attachBookingToGuests(state, rateSelectionKey);

    return { ...state };
}

function onChangeRateSelection(state, { inventory, rate, zone, booking, key }) {
    //copy for compare changes
    let oldRateSelection = { ...state.rateSelections[key] };

    let newKey = getRateSelectionKey({ inventory_uuid: inventory.uuid, rate_id: rate?.id });

    let rateSelection = state.rateSelections[key];

    //Case rateSelectionKey has changed => automatically trigger onRetrieveBookings => attachBookingToGuests
    if (newKey !== key) {
        rateSelection.bookings = [];
        rateSelection.bookings_for_zone = [];
        rateSelection.inventory_uuid = inventory.uuid;
        rateSelection.inventory = inventory;
        rateSelection.max = null;
        rateSelection.key = newKey;
        state = deleteRateSelection(state, { key });
    }

    rateSelection.is_manual = false;
    rateSelection.rate = rate;
    rateSelection.zone = zone;
    rateSelection.first_booking = booking;

    state.rateSelections[newKey] = { ...rateSelection };

    if (!booking && zone) {
        state = setFirstBooking(state, newKey);
    }

    if (booking && state.rateSelections[newKey].first_booking !== oldRateSelection.first_booking) {
        state = attachBookingToGuests(state, newKey);
    }

    return { ...state };
}

function onRetrieveBookings(state, { inventory, rate, bookings, isGA }) {
    let key = getRateSelectionKey({ inventory_uuid: inventory.uuid, rate_id: rate.id });
    let rateSelection = { ...state.rateSelections[key] };
    if (rateSelection) {
        rateSelection.bookings = bookings;
        rateSelection.isGA = isGA;
        bookings.forEach((booking) => {
            rateSelection.bookings_by_id[booking.booking_uuid] = booking;
        });
        state.rateSelections[key] = rateSelection;
        state = setFirstBooking(state, key);
    }

    return { ...state };
}

export function onGuestsChange(state, previousGuests) {
    /*
        This function is triggered when list of guests change.
        The new guests are in state.guests, and the former one in previousGuests list.

        The objective here, is to ensure : 
        1/ We free the booking taken by guests that are removed.
        2/ We take booking for new guests.
    */
    let previousKeys = previousGuests.map((guest) => guest.key);
    let newKeys = state.guests.map((guest) => guest.key);
    let removedKeys = previousKeys.filter((key) => !newKeys.includes(key));
    let addedKeys = newKeys.filter((key) => !previousKeys.includes(key));

    if (addedKeys || removedKeys) {
        // Replace all autobooking rateselection.
        Object.values(state.rateSelections).forEach((rateSelection) => {
            if (!rateSelection.is_manual) {
                state = attachBookingToGuests(state, rateSelection.key);
            }
        });
    }

    if (removedKeys) {
        // Let's free the booking used by theses guests.
        removedKeys.forEach((key) => {
            let guest = previousGuests.filter((guest) => guest.key === key)[0];

            Object.values(state.rateSelections).forEach((rateSelection) => {
                if (rateSelection.is_manual) {
                    let oldBooking = guest.bookings[rateSelection.key];
                    if (oldBooking) {
                        delete state.bookingsUsed[rateSelection.key][oldBooking.booking_uuid];
                    }
                }
            });
        });
    }

    if (addedKeys) {
        // Let's add bookings to this new guest.
        addedKeys.forEach((key) => {
            let guest = state.guests.filter((guest) => guest.key === key)[0];

            Object.values(state.rateSelections).forEach((rateSelection) => {
                if (rateSelection.is_manual) {
                    let newBooking = rateSelection.bookings.filter(
                        (b) => (b.seat_zone || b.area_zone) === rateSelection.zone && !state.bookingsUsed[rateSelection.key][b.booking_uuid]
                    )[0];
                    if (newBooking) {
                        guest.bookings[rateSelection.key] = newBooking;
                        state.bookingsUsed[rateSelection.key][newBooking.booking_uuid] = newBooking;
                    }
                }
            });
        });
    }

    return state;
}

function onChangeSeatingGuest(state, { zone_id, booking, rateSelectionKey, indexGuest }) {
    let rateSelection = state.rateSelections[rateSelectionKey];
    let guestBooking = state.guests[indexGuest].bookings[rateSelectionKey];
    let bookingsUsed = state.bookingsUsed[rateSelectionKey];
    if (guestBooking) {
        delete bookingsUsed[guestBooking.booking_uuid];
    }

    let newBooking = booking;
    if (!newBooking) {
        //if change only zone select, autobooking with first available
        //TODO manage case if no bookings available for this zone
        newBooking = rateSelection.bookings.filter((b) => (b.seat_zone || b.area_zone) === zone_id && !bookingsUsed[b.booking_uuid])[0];
    }

    state.guests[indexGuest].bookings[rateSelectionKey] = newBooking;
    state.bookingsUsed[rateSelectionKey][newBooking.booking_uuid] = newBooking;

    state.rateSelections[rateSelectionKey].is_manual = true;

    return { ...state };
}

export function attendeeCreationReducer(state, { type, ...action }) {
    let previousGuests = state.guests;
    // eslint-disable-next-line default-case
    switch (type) {
        case 'guests':
            if (state.guests === action.guests) {
                // If the reducer is called with the same guest objects, it should do anything.
                // We shouldn't edit the initial object but pass a new one !
                return state;
            }

            state.guests = action.guests;
            state = onGuestsChange(state, previousGuests);
            return { ...state };
        case 'quantity':
            if (state.guests.length === action.quantity) {
                // If the quantity hasn't changed, no need to change the state and do anything.
                // Doesn't allow quantity to go beyond 1
                return state;
            }

            if (state.guests.length > action.quantity) {
                state.guests = state.guests.slice(0, action.quantity);
            } else {
                let newGuests = Array(action.quantity - state.guests.length)
                    .fill()
                    .map(() => {
                        if (state.guests.length === 0) {
                            // Prevent accessing unexisting keys (when adding a quantity starting from 0)
                            return { ...emptyGuest() };
                        }
                        return {
                            ...emptyGuest(),
                            first_name: state.guests[0].first_name,
                            last_name: state.guests[0].last_name,
                            email: state.guests[0].email,
                            form: state.guests[0].form
                        };
                    });
                state.guests = state.guests.concat(newGuests);
            }
            state = onGuestsChange(state, previousGuests);
            return { ...state };
        case 'relaodGuestsForReinvite':
            state.guests = [...action.guestsDuplicated];
            return { ...state };
        case 'onChangeSeatingGuest':
            return onChangeSeatingGuest(state, { ...action });
        case 'anonymousMode':
            state.anonymousMode = action.anonymousMode;
            if (action.anonymousMode) {
                state.guests = state.guests.map((obj) => {
                    return {
                        ...obj,
                        first_name: state.guests[0].first_name,
                        last_name: state.guests[0].last_name,
                        email: state.guests[0].email,
                        form: state.guests[0].form
                    };
                });
            }
            return { ...state };
        case 'setRateSelections':
            state.rateSelections = { ...action.rateSelections };
            return { ...state };
        case 'addDefaultRateSelection': {
            let { key, defaultRateSelection } = getDefaultRateSelection(action.inventories);
            state.rateSelections[key] = defaultRateSelection;
            return { ...state };
        }
        case 'changeRateSelection':
            return onChangeRateSelection(state, { ...action });
        case 'deleteRateSelection':
            return deleteRateSelection(state, { ...action });
        case 'onRetrieveBookings':
            return onRetrieveBookings(state, { ...action });
    }

    return state;
}
