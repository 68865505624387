import React from 'react';
import { Card, ContentCard, Form, OptionalForm } from '@weezevent/nacre';
import { useTranslation } from 'react-i18next';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import Styles from '../../styles/components/invitations/invitations-list.module.css';
import { capitalizeFirstLetter } from '../../tooling';

function Invitation({ index, guest, hasPartnerForm, rateSelection, rateSelectionKey, bookingsUsed = {}, dispatch, state }) {
    const { t } = useTranslation();

    const titleInvitation = React.useMemo(() => {
        if (!hasPartnerForm) {
            return `${guest.last_name ? `${guest.last_name?.toUpperCase()} ` : ''}${capitalizeFirstLetter(guest.first_name)}`;
        }
        return `${'ws_2' in guest.form ? `${guest.form['ws_2']?.toUpperCase()} ` : ''}${'ws_3' in guest.form ? capitalizeFirstLetter(guest.form['ws_3']) : ''}`;
    }, [hasPartnerForm, guest, state]);

    const guestZone = React.useMemo(() => {
        let booking = guest.bookings[rateSelectionKey];
        if (!booking) {
            return null;
        }
        return booking.seat_zone || booking.area_zone;
    }, [state, guest, rateSelectionKey]);

    const zonesOptions = React.useMemo(() => {
        let bookings = [...rateSelection.bookings];
        let guestBooking = guest.bookings[rateSelectionKey];

        if (!bookings || !bookings.length) {
            return [];
        }

        //Filter zone with bookings available and current booking guest
        let bookings_filtered = bookings.filter((x) => !bookingsUsed[x.booking_uuid] || guestBooking?.booking_uuid === x.booking_uuid);
        if (!bookings_filtered.length) {
            return [];
        }

        let zones_options = bookings_filtered.map((x) => {
            return { key: x.seat_zone || x.area_zone, text: x.seat_zone_name || x.area_zone_name, value: x.seat_zone || x.area_zone };
        });

        const arrayUniqueByKey = [...new Map(zones_options.map((item) => [item['key'], item])).values()];

        return arrayUniqueByKey;
    }, [state, rateSelection.bookings, bookingsUsed, guest]);

    const seatsOptions = React.useMemo(() => {
        let bookings = rateSelection.bookings;
        let guestBooking = guest.bookings[rateSelectionKey];

        if (rateSelection.isGA || !guestBooking || !guestZone || !bookings || !bookings.length) {
            return [];
        }

        let bookings_filtered = bookings.filter(
            (b) => guestBooking.booking_uuid === b.booking_uuid || ((b.seat_zone === guestZone || b.area_zone === guestZone) && !bookingsUsed[b.booking_uuid])
        );

        return bookings_filtered.map((x) => {
            return {
                key: x.booking_uuid,
                text: t('partners.invitation.table.seated_seating', { access: x.seat_access, row: x.seat_row, seat: x.seat_seat }),
                value: x.booking_uuid,
                booking: x.object
            };
        });
    }, [state, guest, guestZone, rateSelection, bookingsUsed]);

    if (!rateSelection || rateSelection?.new_event || !guest) {
        return null;
    }

    return (
        <Card className={Styles['invitation-container']}>
            <Card.Header>
                <Card.Title title={titleInvitation} />
                <Card.SubTitle title={`${rateSelection.inventory?.name} ${rateSelection.rate ? `- ${rateSelection.rate.name || rateSelection.rate.rate_template.name}` : ''}`} />
            </Card.Header>
            <ContentCard>
                <Form>
                    <div className={Styles['invitation-form-container']}>
                        <div className={Styles['invitation-input']}>
                            <Form.Label label={t('partners.invitation.creation.columns.header.zone')} />
                            <SemanticDropdown
                                fluid
                                search
                                selection
                                disabled={zonesOptions.length === 1 && guestZone === zonesOptions[0].value}
                                placeholder={t('partners.invitation.creation.columns.header.zone')}
                                options={zonesOptions}
                                onChange={(e, { value }) => {
                                    dispatch({ type: 'onChangeSeatingGuest', zone_id: value, booking: null, rateSelectionKey, indexGuest: index });
                                }}
                                value={guestZone}
                            />
                        </div>
                        {!rateSelection.isGA && (
                            <div className={Styles['invitation-input']}>
                                <Form.Label label={t('partners.invitation.creation.columns.header.seat')} />
                                <SemanticDropdown
                                    fluid
                                    search
                                    selection
                                    disabled={!seatsOptions.length}
                                    placeholder={t('partners.invitation.creation.columns.header.seat')}
                                    options={seatsOptions}
                                    onChange={(event, { value }) => {
                                        let bookingOption = seatsOptions.find((booking) => booking.value === value);
                                        dispatch({ type: 'onChangeSeatingGuest', zone_id: guestZone, booking: bookingOption.booking, rateSelectionKey, indexGuest: index });
                                    }}
                                    value={guest.bookings[rateSelectionKey] ? guest.bookings[rateSelectionKey].booking_uuid : null}
                                />
                            </div>
                        )}
                    </div>
                </Form>
            </ContentCard>
        </Card>
    );
}

function InvitationsListByInventory({ state, hasPartnerForm, rateSelection, rateSelectionKey, dispatch }) {
    //Hide if rate selection is GA and only one zone
    const rateSelectionGAwithOneZone = React.useMemo(() => {
        if (rateSelectionKey !== 'new_event' && rateSelection.bookings.length) {
            if (rateSelection.bookings[0].area_zone && rateSelection.bookings.length === rateSelection.bookings_for_zone.length) {
                return true;
            }
        }
        return false;
    }, [state, rateSelectionKey, rateSelection]);

    if (rateSelectionKey === 'new_event' || !rateSelection || !state.guests?.length || rateSelectionGAwithOneZone) {
        return null;
    }

    return (
        <>
            {state.guests.map((guest, index) => (
                <Invitation
                    key={rateSelectionKey + index}
                    state={state}
                    index={index}
                    hasPartnerForm={hasPartnerForm}
                    rateSelection={rateSelection}
                    rateSelectionKey={rateSelectionKey}
                    guest={guest}
                    bookingsUsed={state.bookingsUsed[rateSelectionKey]}
                    dispatch={dispatch}
                />
            ))}
        </>
    );
}

export function InvitationsList({ state, dispatch, invitationsCount, hasPartnerForm }) {
    const { t } = useTranslation();

    //Hide list if rate selection is GA and only one zone
    const onlyOneRateSelectionGA = React.useMemo(() => {
        let _rateSelections = Object.values(state.rateSelections);
        if (!state.rateSelections.new_event && _rateSelections.length === 1 && _rateSelections[0].bookings.length) {
            if (_rateSelections[0].bookings[0].area_zone && _rateSelections[0].bookings.length === _rateSelections[0].bookings_for_zone.length) {
                return true;
            }
        }
        return false;
    }, [state]);

    if (invitationsCount === 0 || invitationsCount > 100 || onlyOneRateSelectionGA) {
        return null;
    }

    return (
        <div className={Styles['invitations-list']}>
            <OptionalForm
                className={Styles['invitations-list-optional-form']}
                labelOpen={<span>{t('partners.invitation.creation.optional_form_manual_seating.open')}</span>}
                labelClosed={<span>{t('partners.invitation.creation.optional_form_manual_seating.close')}</span>}
                open={invitationsCount <= 10}
            >
                {Object.entries(state.rateSelections).map(([rsKey, rs]) => (
                    <InvitationsListByInventory key={rsKey} hasPartnerForm={hasPartnerForm} rateSelection={rs} rateSelectionKey={rsKey} state={state} dispatch={dispatch} />
                ))}
            </OptionalForm>
        </div>
    );
}
