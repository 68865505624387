import React from 'react';
import { Card, ContentCard, Table } from '@weezevent/nacre';
import Styles from '../../styles/components/stats/stats-sidebar-table.module.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InfoTextTooltip } from '../ticketing/infotext-tooltip/infoTextTooltip';

function RatioQuotas({ available, total }) {
    return (
        <div>
            <span>
                <span className={Styles['quotas-available']}>{available}</span>
                {` / ${total}`}
            </span>
        </div>
    );
}

RatioQuotas.propTypes = {
    available: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
};

function RateRowStat({ rate }) {
    return (
        <Table.Row>
            <Table.Cell className={Styles['row-stat']}>{rate.name || rate.rate_template?.name}</Table.Cell>
            <Table.Cell className={`${Styles['row-stat']} ${Styles['row-stat-rates']}`}>
                <RatioQuotas available={rate.available} total={rate.total} />
            </Table.Cell>
        </Table.Row>
    );
}

RateRowStat.propTypes = {
    rate: PropTypes.object.isRequired
};

function InventoryRowStat({ inventory }) {
    let date = new Date(inventory.event.start_date).toLocaleString([], { timeStyle: 'long', dateStyle: 'medium', timeZone: inventory.event.timezone });
    return (
        <React.Fragment>
            <Table.Row className={Styles['inventory-row']}>
                <Table.Cell className={Styles['row-stat']}>
                    <InfoTextTooltip className={Styles['inventory-name']} title={inventory.name} subtitle={date} />
                </Table.Cell>
                <Table.Cell className={`${Styles['row-stat']} ${Styles['row-stat-rates']}`}>
                    <RatioQuotas available={inventory.available} total={inventory.total} />
                </Table.Cell>
            </Table.Row>
            {inventory.rates.map((rate) => (
                <RateRowStat key={rate.id} rate={rate} />
            ))}
        </React.Fragment>
    );
}

InventoryRowStat.propTypes = {
    inventory: PropTypes.object.isRequired
};

export function TableQuotasCard({ datas }) {
    const { t } = useTranslation();
    const [showAll, setShowAll] = React.useState(false);

    const inventories = React.useMemo(() => {
        if (!datas) {
            return [];
        }
        if (showAll) {
            return datas;
        }
        return datas.filter((inventory) => (inventory.event?.end_date ? inventory.event.end_date > new Date().toISOString() : true));
    }, [datas, showAll]);

    const onShowAll = React.useCallback(() => {
        if (!showAll) {
            setShowAll(true);
        } else {
            setShowAll(false);
        }
    }, [showAll]);

    if (!datas) {
        return null;
    }

    return (
        <Card className={Styles['stat-side-bar']}>
            <ContentCard>
                <Table className={Styles['table-stat']}>
                    <Table.Header>
                        <Table.Row>
                            <Table.Header.Cell className={Styles['row-stat']}>{t('partners.invitation.table.side_menu.stats.card.event_name')}</Table.Header.Cell>
                            <Table.Header.Cell className={Styles['row-stat']}>{t('partners.invitation.table.side_menu.stats.card.quotas_left')}</Table.Header.Cell>
                        </Table.Row>
                    </Table.Header>
                    {inventories.length ? (
                        <Table.Body>
                            {inventories.map((inventory) => (
                                <InventoryRowStat key={inventory.name} inventory={inventory} />
                            ))}
                        </Table.Body>
                    ) : (
                        t('partners.invitation.table.side_menu.stats.no_inventory')
                    )}
                </Table>
                {(showAll || (!showAll && datas.length > inventories.length)) && (
                    <span className={Styles['clickable-span-show-inventories']} onClick={() => onShowAll()}>
                        {showAll ? t('partners.invitation.table.side_menu.stats.hide_ended_events') : t('partners.invitation.table.side_menu.stats.display_ended_events')}
                    </span>
                )}
            </ContentCard>
        </Card>
    );
}

TableQuotasCard.propTypes = {
    datas: PropTypes.array.isRequired
};
