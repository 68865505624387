import React from 'react';
import PropTypes from 'prop-types';
import SideBar from './portal';
import { StandardHeader, Card, ContentCard, Form, ToastContext, Button, Icon } from '@weezevent/nacre';
import Styles from '../../styles/components/invitations/attendee-sidebar.module.css';
import EditStyles from '../../styles/components/invitations/edit-attendee-card.module.css';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import DisplayStatus from './status';
import { SeatingInformationCard } from './seatingCard';
import { getRateSelectionKey } from './attendeeCreationDrawer/state';
import { emailValidation } from '../../tooling';
import { isActionDisabled } from './list';
import i18n from 'i18next';

function AttendeeHeader({ attendee }) {
    if (!attendee) {
        return null;
    }

    return <StandardHeader title={<span className={Styles['attendee-summary-card']}>{`${attendee.first_name} ${attendee.last_name} - ${attendee.barcode}`}</span>} />;
}

AttendeeHeader.propTypes = {
    attendee: PropTypes.object
};

function CardResumeStatus({ action }) {
    return (
        <Card className={Styles['email-status-card']}>
            <ContentCard>
                <div className={Styles['tooltip-mail-status-container']}>
                    <DisplayStatus status={action.event} position={'right'} disableTooltip={true} />
                </div>
                <div>{new Date(action.action_date).toLocaleString()}</div>
            </ContentCard>
        </Card>
    );
}

CardResumeStatus.propTypes = {
    action: PropTypes.object.isRequired
};

function HistoryMailStatus({ attendee, partnerId, mailStatusModel }) {
    const { t } = useTranslation();
    const [mailStatus, setMailStatus] = React.useState([]);
    const toast = React.useContext(ToastContext);

    React.useEffect(() => {
        // Retrieve dynamically messages from pat
        if (attendee) {
            let resultPromises = [];
            for (let message of attendee.mail_trackers) {
                // prevent to get fake pat tracker request (tracker with status pending) and filter by not failed
                if (!['Pending', 'failed'].includes(message['status'])) {
                    resultPromises.push(
                        mailStatusModel.get({
                            partnerId: partnerId,
                            attendeeId: attendee.pk,
                            id: message['uuid']
                        })
                    );
                }
            }
            Promise.all(resultPromises)
                .then((result) => {
                    let messages = [];
                    for (let rawMessage of result) {
                        messages.push(rawMessage.object);
                    }
                    setMailStatus(messages);
                })
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    toast.error(t('partners.invitation.table.side_menu.history.error_details_message'));
                });
        }
    }, [attendee, mailStatusModel, partnerId, setMailStatus, t]);

    const status = React.useMemo(() => {
        let statuses = mailStatus.map((message) => message.actions).flat();

        attendee.scanned &&
            statuses.push({
                event: 'scanned',
                action_date: attendee.scan_date
            });

        statuses.sort((a, b) => new Date(b.action_date) - new Date(a.action_date));
        return statuses;
    }, [mailStatus, attendee]);

    if (!status.length) {
        return null;
    }

    return (
        <>
            {attendee?.mail_tracking_count > 1 ? `${t('partners.invitation.table.side_menu.history.email_sent')} ${attendee?.mail_tracking_count} ` : null}
            {status.map((action) => {
                return <CardResumeStatus key={action.action_date} action={action} attendee_mail={attendee?.mail} />;
            })}
        </>
    );
}
HistoryMailStatus.propTypes = {
    attendee: PropTypes.object.isRequired,
    partnerId: PropTypes.string.isRequired,
    mailStatusModel: PropTypes.object
};

const build_attendee_form = (form) => {
    if (!Array.isArray(form) && form instanceof Object) {
        return form;
    }

    return form.reduce((acc, { weez_id, answer }) => {
        // OMG
        acc[weez_id.replace('-', '_')] = answer;
        return acc;
    }, {});
};

function EditAttendee({
    language,
    attendee,
    partnerId,
    onSubmit = () => {},
    inventories = [],
    rates = [],
    rateModel,
    bookingModel,
    form,
    hasPartnerForm,
    disabledEdition = false
}) {
    const [firstName, setFirstName] = React.useState(null);
    const [lastName, setLastName] = React.useState(null);
    const [company, setCompany] = React.useState(null);
    const [email, setMail] = React.useState(null);
    const [attendeeFields, setAttendeeFields] = React.useState({});
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const initialRateSelection = React.useMemo(() => {
        return {
            inventory: attendee?.booking ? inventories.find((inv) => inv.uuid === attendee.booking.inventory) : null,
            bookings: [],
            rate: attendee?.booking ? rates.find((r) => r.id === Number(attendee.booking.rate)) : null,
            zone: attendee?.booking ? attendee.booking.seat_zone || attendee.booking.area_zone : null,
            isGA: Boolean(attendee?.booking['area_zone']),
            booking: attendee?.booking
        };
    }, [attendee, inventories, rates]);

    const [rateSelection, setRateSelection] = React.useState(initialRateSelection);

    const toast = React.useContext(ToastContext);
    const { t } = useTranslation();

    const currentCompany = React.useMemo(() => {
        if (!attendee) {
            return null;
        }
        return attendee.form?.find(({ weez_id }) => weez_id === 'ws-9')?.answer || '';
    }, [attendee]);

    const partnerForm = React.useMemo(() => {
        if (!form) {
            return [];
        }
        return form.map((field) => {
            const id = field.form_field.weez_id.replace('_', '-');
            field.form_field.weez_id = id;
            return field;
        });
    }, [form]);

    const attendeeFormFields = React.useMemo(() => {
        if (!attendee || !hasPartnerForm) {
            return [];
        }
        const formQuestions = partnerForm.map((field) => {
            return field.form_field.weez_id;
        });
        const defaultFields = ['ws-2', 'ws-3', 'ws-5', 'ws-9'];
        return attendee?.form.filter((fd) => formQuestions.includes(fd.weez_id) && !defaultFields.includes(fd.weez_id));
    }, [attendee, partnerForm, hasPartnerForm]);

    const handleRestore = React.useCallback(() => {
        let formFields = {};
        const { first_name = '', last_name = '', email = '' } = attendee;
        setFirstName(first_name);
        setLastName(last_name);
        setMail(email);
        setCompany(currentCompany);
        setRateSelection(initialRateSelection);

        if (attendeeFormFields.length) {
            attendeeFormFields.forEach((field) => {
                formFields[field.weez_id] = field.answer;
            });
            setAttendeeFields(formFields);
        }
    }, [attendee, currentCompany, initialRateSelection, attendeeFormFields, partnerForm]);

    const handleSubmit = React.useCallback(
        (event) => {
            event.preventDefault();
            setIsSubmitting(true);
            if (!email) {
                return toast.error(t('partners.invitation.table.side_menu.attendee.missing_fields'));
            }

            let newAttendee = attendee.copy();
            newAttendee.first_name = firstName;
            newAttendee.last_name = lastName;
            newAttendee.email = email;

            if (rateSelection.booking.booking_uuid !== attendee.booking.booking_uuid) {
                newAttendee.booking = rateSelection.booking;
                newAttendee.new_booking = rateSelection.booking.booking_uuid;
            }

            // hard coded company
            newAttendee.form = build_attendee_form(newAttendee.form || {});
            newAttendee.form['ws_9'] = company;

            if (attendeeFormFields.length && Object.keys(attendeeFields).length) {
                Object.entries(attendeeFields).forEach(([key, value]) => {
                    const formKey = attendee?.form.find((k) => k.weez_id === key).weez_id.replace('-', '_');
                    newAttendee.form[formKey] = value;
                });
            }

            delete newAttendee.rate;
            delete newAttendee.order;
            newAttendee
                .save({
                    partnerId,
                    pk: attendee.pk
                })
                .then(() => {
                    toast.success(t('partners.invitation.table.side_menu.attendee.edit_success'));
                    onSubmit();
                })
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    toast.error(t('partners.invitation.table.side_menu.attendee.edit_error'));
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [firstName, lastName, email, toast, attendee, t, partnerId, onSubmit, currentCompany, company, rateSelection, attendeeFormFields, attendeeFields]
    );

    const valid_mail = React.useMemo(() => {
        if (!email) {
            return true;
        }
        return emailValidation(email);
    }, [email, emailValidation]);

    const disabled = React.useMemo(() => {
        if (!attendee) {
            return true;
        }
        const { first_name, last_name, email: attendee_mail, booking: attendee_booking } = attendee;

        if (!valid_mail) {
            return true;
        }

        return (
            firstName === first_name &&
            last_name === lastName &&
            email === attendee_mail &&
            currentCompany === company &&
            rateSelection.booking?.booking_uuid === attendee_booking.booking_uuid
        );
    }, [attendee, firstName, lastName, email, currentCompany, company, rateSelection, valid_mail]);

    React.useEffect(() => {
        if (attendee) {
            handleRestore();
        }
    }, [handleRestore, attendee]);

    return (
        <div>
            <Card className={EditStyles['container']}>
                <Card.Header>
                    <Card.Title className={EditStyles['title']} title={t('partners.invitation.table.side_menu.attendee.title')} />
                </Card.Header>
                <ContentCard>
                    <Form className={EditStyles['form']}>
                        <div className={EditStyles['input-field-container']}>
                            <Form.FieldBlock
                                label={t('partners.invitation.table.side_menu.attendee.first_name')}
                                name="firsname"
                                value={firstName}
                                onChange={(e, { value }) => setFirstName(value === 0 ? '' : value)}
                                disabled={disabledEdition}
                            />
                            <Form.FieldBlock
                                label={t('partners.invitation.table.side_menu.attendee.last_name')}
                                name="lastname"
                                value={lastName}
                                onChange={(e, { value }) => setLastName(value === 0 ? '' : value)}
                                disabled={disabledEdition}
                            />
                        </div>
                        <Form.FieldBlock
                            label={t('partners.invitation.creation.columns.header.enterprise')}
                            name="company"
                            value={company}
                            onChange={(e, { value }) => setCompany(value === 0 ? '' : value)}
                            disabled={disabledEdition}
                        />
                        <Form.FieldBlock
                            className={EditStyles['input-mail']}
                            label={t('partners.invitation.table.side_menu.attendee.email')}
                            name="email"
                            value={email}
                            onChange={(e, { value }) => setMail(value === 0 ? '' : value)}
                            errors={!valid_mail}
                            disabled={disabledEdition}
                        />
                        <AttendeeFormAnswer
                            partnerForm={partnerForm}
                            attendeeFormFields={attendeeFormFields}
                            attendeeFields={attendeeFields}
                            setAttendeeFields={setAttendeeFields}
                            language={language}
                            disabled={disabledEdition}
                        />
                    </Form>
                </ContentCard>
            </Card>

            {attendee && attendee.is_seat_editable && rateSelection.booking && rateSelection.inventory && rateSelection.rate && (
                <SeatingInformationCard
                    keyRateSelection={getRateSelectionKey({ inventory_uuid: rateSelection.inventory.uuid, rate_id: rateSelection.rate.id })}
                    edit
                    initialRateSelection={initialRateSelection}
                    rateSelection={rateSelection}
                    rate={rateSelection.rate}
                    inventory={rateSelection.inventory}
                    partnerId={partnerId}
                    inventories={inventories}
                    rateModel={rateModel}
                    bookingModel={bookingModel}
                    onChange={(inventory, rate, zone_id, booking) => {
                        let _rateSelection = { ...rateSelection };
                        if (_rateSelection.inventory.uuid !== inventory.uuid) {
                            // if we change inventory, reset booking list and re set new key
                            _rateSelection.bookings = [];
                            _rateSelection.inventory = inventories.find((inv) => inv.uuid === inventory.uuid);
                        }

                        _rateSelection.rate = rate;
                        _rateSelection.zone = zone_id;

                        let newBooking = booking;
                        if (!newBooking) {
                            //if change only zone select, autobooking with first available
                            //TODO manage case if no bookings available for this zone
                            newBooking = rateSelection.bookings.filter((b) => (b.seat_zone || b.area_zone) === zone_id)[0];
                        }

                        _rateSelection.booking = newBooking;
                        setRateSelection({ ..._rateSelection });
                    }}
                    onRetrieveBookings={(booking_list, inventory, rate) => {
                        let _rateSelection = { ...rateSelection };
                        let previousRate = _rateSelection.rate;
                        _rateSelection.bookings = booking_list;
                        _rateSelection.rate = rate;
                        _rateSelection.isGA = Boolean(booking_list[0]['area_zone']);

                        if (booking_list.length && booking_list[0]['area_zone'] && parseInt(previousRate.rate?.id) !== rate.id) {
                            // if it is a GA, we cannot choose booking, so we cannot submit, so we pick the first
                            // booking of the booking list.
                            _rateSelection.booking = booking_list[0].booking_uuid;
                            _rateSelection.zone = booking_list[0].area_zone;
                        }
                        setRateSelection(_rateSelection);
                    }}
                />
            )}

            <div className={EditStyles['footer-button-container']}>
                <Button.Group className={EditStyles['footer-button-group']}>
                    <Button primary disabled={disabled || isSubmitting} isLoading={isSubmitting} onClick={handleSubmit} label={t('partners.invitation.table.save')} />
                    <Button disabled={disabled} label={t('partners.invitation.table.side_menu.attendee.restore')} onClick={handleRestore} />
                </Button.Group>
            </div>
        </div>
    );
}

EditAttendee.propTypes = {
    attendee: PropTypes.object,
    partnerId: PropTypes.string,
    onSubmit: PropTypes.func
};

function AttendeeHistoryCard({ attendee }) {
    const { t } = useTranslation();
    const locale = i18n.language;

    if (!attendee) {
        return null;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title className={EditStyles['title']} title={t('partners.invitation.table.side_menu.attendee-history.title')} />
            </Card.Header>
            <ContentCard>
                <div className={EditStyles['attendee-history-container']}>
                    {attendee.scanned ? (
                        <div className={EditStyles['attendee-history']}>
                            <Icon name="check-circle" />
                            <div>{t('partners.invitation.table.side_menu.attendee-history.ticket-scanned')}</div>
                        </div>
                    ) : (
                        <div className={EditStyles['attendee-history']}>
                            <Icon name="cross-circle" />
                            <div>{t('partners.invitation.table.side_menu.attendee-history.ticket-not-scanned')}</div>
                        </div>
                    )}
                    {attendee.downloads_count > 0 ? (
                        <div>
                            <div className={EditStyles['attendee-history']}>
                                <Icon name="check-circle" />
                                <div>{t('partners.invitation.table.side_menu.attendee-history.ticket-downloaded', { count: attendee.downloads_count })}</div>
                            </div>
                            <div className={EditStyles['attendee-history-last-downloaded']}>
                                <div>{t('partners.invitation.table.side_menu.attendee-history.last-ticket-downloaded')}</div>
                                <div className={EditStyles['attendee-history-last-downloaded-date']}>{new Date(attendee.latest_download_datetime).toLocaleString(locale)}</div>
                            </div>
                        </div>
                    ) : (
                        <div className={EditStyles['attendee-history']}>
                            <Icon name="cross-circle" />
                            <div>{t('partners.invitation.table.side_menu.attendee-history.ticket-not-downloaded')}</div>
                        </div>
                    )}
                </div>
            </ContentCard>
        </Card>
    );
}
export default function AttendeeSideBar({
    attendee = null,
    depth = 0,
    onClickAway = () => {},
    partner,
    onSubmit = () => {},
    mailStatusModel,
    inventories,
    rates,
    rateModel,
    bookingModel,
    form,
    language,
    hasPartnerForm,
    organizationId
}) {
    const disabledEdition = React.useMemo(() => {
        const ticket_edition_conditions = partner.settings?.attendees_management?.ticket_edition_conditions;
        if (organizationId || !attendee) {
            return false;
        }
        if (!ticket_edition_conditions) {
            return attendee.scanned;
        }
        return isActionDisabled(ticket_edition_conditions, attendee);
    }, [organizationId, partner, attendee, isActionDisabled]);

    return (
        <SideBar className={Styles['container']} open={Boolean(attendee)} depth={depth} onClickAway={onClickAway} onClose={onClickAway}>
            <AttendeeHeader attendee={attendee} />
            <EditAttendee
                attendee={attendee}
                partnerId={partner.pk}
                form={form}
                hasPartnerForm={hasPartnerForm}
                onSubmit={onSubmit}
                inventories={inventories}
                rates={rates}
                language={language}
                rateModel={rateModel}
                bookingModel={bookingModel}
                disabledEdition={disabledEdition}
            />
            <AttendeeHistoryCard attendee={attendee} />
            {attendee && (attendee.mail_trackers.length !== 0 || attendee.scanned) && (
                <HistoryMailStatus attendee={attendee} partnerId={partner.pk} mailStatusModel={mailStatusModel} />
            )}
        </SideBar>
    );
}

AttendeeSideBar.propTypes = {
    attendee: PropTypes.object,
    depth: PropTypes.number,
    onClickAway: PropTypes.func,
    partner: PropTypes.object,
    onSubmit: PropTypes.func,
    mailStatusModel: PropTypes.func,
    disabledEdition: PropTypes.bool
};

function AttendeeFormAnswer({ partnerForm, attendeeFormFields, attendeeFields, setAttendeeFields, language, disabled }) {
    const handleChange = React.useCallback(
        (value, key) => {
            const form = { ...attendeeFields };
            form[key] = value;
            setAttendeeFields(form);
        },
        [attendeeFields, setAttendeeFields]
    );

    if (!partnerForm || !partnerForm.length) {
        return null;
    }

    return attendeeFormFields.map((field) => {
        const fieldName = partnerForm.find((fd) => fd.form_field.weez_id === field.weez_id).form_field;
        return (
            <Form.FieldBlock
                key={fieldName.weez_id}
                label={fieldName.translations.label[language]}
                name={fieldName.label}
                value={attendeeFields[fieldName.weez_id]}
                onChange={(e, { value }) => handleChange(value, fieldName.weez_id)}
                disabled={disabled}
            />
        );
    });
}

AttendeeFormAnswer.propTypes = {
    partnerForm: PropTypes.object,
    attendeeFormFields: PropTypes.object,
    attendeeFields: PropTypes.object,
    setAttendeeFields: PropTypes.func,
    language: PropTypes.string,
    disabled: PropTypes.bool
};
