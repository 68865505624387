import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Status, Tooltip } from '@weezevent/nacre';

function StatusComponent({ text, color = 'grey', tooltipText = null, disableTooltip = false }) {
    if (tooltipText && !disableTooltip) {
        return (
            <Tooltip margin={false} title={tooltipText} triggerOnHover={true} position={'top'}>
                <Status text={text} color={color} />
            </Tooltip>
        );
    }
    return <Status text={text} color={color} />;
}
StatusComponent.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    tooltipText: PropTypes.string,
    disableTooltip: PropTypes.bool
};

/**
 *
 * @param status : status of mail (in pat tracker)
 * @param attendee_mail : email of failure Hard bounce
 */
export default function DisplayStatus({ status = null, attendee_mail = null, disableTooltip = false }) {
    const { t } = useTranslation();
    let statusWording = React.useMemo(() => {
        let wording = new Map();
        wording.set('valid', { color: 'grey', text: t('partners.invitation.table.mail_status.sending'), tooltip: null });

        // valid status
        wording.set('delivery', { color: 'green', text: t('partners.invitation.table.mail_status.delivery'), tooltip: null });
        wording.set('send', { color: 'green', text: t('partners.invitation.table.mail_status.send'), tooltip: null });
        wording.set('open', { color: 'blue', text: t('partners.invitation.table.mail_status.open'), tooltip: t('partners.invitation.table.mail_tooltip.open') });
        wording.set('click', { color: 'blue', text: t('partners.invitation.table.mail_status.click'), tooltip: t('partners.invitation.table.mail_tooltip.click') });
        wording.set('pending', { color: 'orange', text: t('partners.invitation.table.mail_status.pending') });

        // failed status
        wording.set('failed', { color: 'red', text: t('partners.invitation.table.mail_status.send_failed'), tooltip: t('partners.invitation.table.mail_tooltip.failed') });
        wording.set('reject', { color: 'red', text: t('partners.invitation.table.mail_status.failed'), tooltip: t('partners.invitation.table.mail_tooltip.reject') });
        wording.set('rendering Failure', {
            color: 'red',
            text: t('partners.invitation.table.mail_status.failed'),
            tooltip: t('partners.invitation.table.mail_tooltip.rendering_failure')
        });
        wording.set('hard-bounce', {
            color: 'red',
            text: t('partners.invitation.table.mail_status.failed'),
            tooltip: t('partners.invitation.table.mail_tooltip.hard_bounce', { email: attendee_mail })
        });
        wording.set('soft-bounce', { color: 'red', text: t('partners.invitation.table.mail_status.failed'), tooltip: t('partners.invitation.table.mail_tooltip.soft_bounce') });
        // scanned
        wording.set('scanned', { color: 'purple', text: t('partners.invitation.table.side_menu.scanned'), tooltip: null });

        return wording;
    }, [attendee_mail, t]);

    let values = statusWording.get(status ? status.toLowerCase() : null);
    if (!values) {
        // If status does not exist
        values = { color: 'grey', text: t('partners.invitation.table.mail_status.no_status'), tooltip: null };
    }
    return <StatusComponent text={values.text} color={values.color} tooltipText={values.tooltip} disableTooltip={disableTooltip} />;
}

DisplayStatus.propTypes = {
    status: PropTypes.string,
    attendee_mail: PropTypes.string,
    disableTooltip: PropTypes.bool
};
