import React from 'react';
import PropTypes from 'prop-types';
import { Card, ContentCard, Form, Icon, Section, TooltipHelp } from '@weezevent/nacre';
import EditStyles from '../../styles/components/invitations/edit-attendee-card.module.css';
import { useTranslation } from 'react-i18next';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import Styles from '../../styles/components/invitations/seating-card.module.css';

function EditSeating({ edit, onChange, inventory, rate, rateSelection, zonesOptions, seatsOptions }) {
    const { t } = useTranslation();

    if (!edit || (rateSelection.isGA && zonesOptions.length <= 1)) {
        return null;
    }

    return (
        <div className={Styles['seating-container']}>
            <div className={Styles['seating-input']}>
                <Form.Label label={t('partners.invitation.creation.columns.header.zone')} />
                <SemanticDropdown
                    fluid
                    search
                    selection
                    disabled={zonesOptions.length === 1}
                    placeholder={t('partners.invitation.creation.columns.header.zone')}
                    options={zonesOptions}
                    onChange={(e, { value }) => {
                        onChange(inventory, rate, value, null);
                    }}
                    value={rateSelection.zone}
                />
            </div>
            {!rateSelection.isGA && (
                <div className={Styles['seating-input']}>
                    <Form.Label label={t('partners.invitation.creation.columns.header.seat')} />
                    <SemanticDropdown
                        fluid
                        search
                        selection
                        placeholder={t('partners.invitation.creation.columns.header.seat')}
                        options={seatsOptions}
                        onChange={(event, { value }) => {
                            let booking = seatsOptions.find((booking) => booking.key === value);
                            onChange(inventory, rate, rateSelection.zone, booking.booking);
                        }}
                        value={rateSelection.booking?.booking_uuid}
                    />
                </div>
            )}
        </div>
    );
}

function FirstBooking({ displayFirstBooking, edit, onChange, inventory, rate, rateSelection, zonesOptions, seatsOptions, isManual }) {
    const { t } = useTranslation();

    if (!displayFirstBooking || edit || !inventory || !rate || !zonesOptions.length || (rateSelection.isGA && zonesOptions.length <= 1)) {
        return null;
    }

    return (
        <Section>
            <div style={{ marginBottom: '10px' }}>
                <span style={{ fontWeight: '500' }}>
                    {!rateSelection.isGA ? t('partners.invitation.creation.first_booking.title') : t('partners.invitation.creation.first_booking.title_ga')}
                    <TooltipHelp
                        position="bottom"
                        triggerOnHover
                        title={!rateSelection.isGA ? t('partners.invitation.creation.first_booking.tooltip') : t('partners.invitation.creation.first_booking.tooltip_ga')}
                    />
                </span>
            </div>
            <div className={Styles['seating-container']}>
                <div className={Styles['seating-input']}>
                    <Form.Label label={t('partners.invitation.creation.columns.header.zone')} optionalText={t('partners.common.labelOptional')} />
                    <SemanticDropdown
                        fluid
                        search
                        disabled={zonesOptions.length === 1}
                        selection
                        placeholder={t('partners.invitation.creation.columns.header.zone')}
                        options={zonesOptions}
                        onChange={(e, { value }) => {
                            onChange(inventory, rate, value, null);
                        }}
                        value={isManual ? 'custom' : rateSelection.zone}
                    />
                </div>
                {!rateSelection.isGA && (
                    <div className={Styles['seating-input']}>
                        <Form.Label label={t('partners.invitation.creation.columns.header.seat')} optionalText={t('partners.common.labelOptional')} />
                        <SemanticDropdown
                            fluid
                            search
                            selection
                            placeholder={t('partners.invitation.creation.columns.header.seat')}
                            options={seatsOptions}
                            onChange={(event, { value }) => {
                                let booking = seatsOptions.find((booking) => booking.key === value);
                                onChange(inventory, rate, rateSelection.zone, booking.booking);
                            }}
                            value={rateSelection.is_manual ? 'custom' : rateSelection.first_booking?.booking_uuid}
                        />
                    </div>
                )}
            </div>
        </Section>
    );
}

export function SeatingInformationCard({
    state,
    keyRateSelection = null,
    edit = false,
    initialRateSelection = null,
    attendee = null,
    rateSelection,
    inventory = null,
    rate = null,
    partnerId,
    inventories = [],
    ratesAlreadySelected = [],
    displayFirstBooking = false,
    onChange = () => {},
    onRetrieveBookings = () => {},
    rateModel,
    bookingModel,
    onDeleteInventory = () => {},
    isManual = false,
    onSingleSelectionPossible = () => {}
}) {
    const { t } = useTranslation();

    let [rates, loadingRates] = rateModel.useApiModel(
        {
            partnerId,
            launch: Boolean(keyRateSelection && keyRateSelection !== 'new_event'),
            query: { inventories: inventory?.uuid, include_unavailable: edit },
            onSuccess: (rates_obj) => {
                if (rates_obj.length === 1 && !edit) {
                    if (inventories.length === 1) {
                        onSingleSelectionPossible(true);
                    }
                    onChange(inventory, rates_obj[0], null, null);
                }
            }
        },
        [partnerId, inventory, keyRateSelection, edit]
    );

    let [bookings, loadingBookings] = bookingModel.useApiModel(
        {
            partnerId,
            launch: Boolean(keyRateSelection && keyRateSelection !== 'new_event' && rate),
            query: { inventories_in: inventory?.uuid, rates: rate?.id },
            onSuccess: (booking) => {
                onRetrieveBookings(booking, inventory, rate, Boolean(booking.length && booking[0]['area_zone']));
            }
        },
        [partnerId, inventory, rate, keyRateSelection]
    );

    const inventoriesOptions = React.useMemo(() => {
        if (!inventories.length) {
            return [];
        }
        return inventories.map((x) => {
            return { key: x.uuid, text: x.name, value: x.uuid, inventory: x };
        });
    }, [inventories, inventory]);

    const ratesOptions = React.useMemo(() => {
        if (!rates || !rates.length) {
            return [];
        }
        return rates
            .filter((r) => r.id === rate?.id || !ratesAlreadySelected.includes(r.id))
            .map((x) => {
                return { key: x.id, text: x.name || x.rate_template.name, value: x.id, rate: x };
            });
    }, [rates, loadingRates, rate, ratesAlreadySelected]);

    const zonesOptions = React.useMemo(() => {
        if (!bookings || !bookings.length) {
            return [];
        }

        let zones_options = bookings.map((x) => {
            return { key: x.seat_zone || x.area_zone, text: x.seat_zone_name || x.area_zone_name, value: x.seat_zone || x.area_zone };
        });

        const arrayUniqueByKey = [...new Map(zones_options.map((item) => [item['key'], item])).values()];

        if (isManual) {
            arrayUniqueByKey.push({ key: 'custom', text: t('partners.invitation.creation.first_booking.option_custom'), value: 'custom' });
        }

        return arrayUniqueByKey;
    }, [bookings, loadingBookings, isManual, t]);

    const seatsOptions = React.useMemo(() => {
        if (!rateSelection.zone || rateSelection.isGA || !bookings || !bookings.length) {
            return [];
        }

        const bookings_filtered = bookings.filter((booking) => booking.seat_zone === rateSelection.zone || booking.area_zone === rateSelection.zone);

        let booking_options = bookings_filtered.map((x) => {
            return {
                key: x.booking_uuid,
                text: t('partners.invitation.table.seated_seating', { access: x.seat_access, row: x.seat_row, seat: x.seat_seat }),
                value: x.booking_uuid,
                booking: x.object
            };
        });

        if (edit && initialRateSelection && initialRateSelection.zone === rateSelection.zone) {
            booking_options.push({
                key: initialRateSelection.booking.booking_uuid,
                text: t('partners.invitation.table.seated_seating', {
                    access: initialRateSelection.booking.seat_access,
                    row: initialRateSelection.booking.seat_row,
                    seat: initialRateSelection.booking.seat_seat
                }),
                value: initialRateSelection.booking.booking_uuid,
                booking: initialRateSelection.booking.object
            });
        }

        if (isManual) {
            booking_options.push({ key: 'custom', text: t('partners.invitation.creation.first_booking.option_custom'), value: 'custom' });
        }

        return booking_options;
    }, [state, bookings, rateSelection.zone, rateSelection.isGA, loadingBookings, isManual, initialRateSelection, edit, t]);

    const maxAvailableReached = React.useMemo(() => {
        if (!rateSelection.zone || !rateSelection.bookings_for_zone) {
            return false;
        }

        return state.guests.length > rateSelection.bookings_for_zone.length;
    }, [rateSelection, state]);

    return (
        <Card className={`${EditStyles['container']} ${Styles['seating-card']}`}>
            <Card.Header>
                <div className={EditStyles['trash-button']}>
                    <Card.Title className={EditStyles['title']} title={t('partners.invitation.table.side_menu.seating_information.title')} />
                    {!edit && Object.keys(state.rateSelections).length > 1 && (
                        <div>
                            <Icon name={'trash'} onClick={() => onDeleteInventory(inventory, rate)} />
                        </div>
                    )}
                </div>
            </Card.Header>
            <ContentCard className={Styles['seating-content-card']}>
                <Form>
                    <div className={Styles['seating-container']}>
                        <div className={Styles['seating-input']}>
                            <Form.Label label={t('partners.invitation.creation.columns.header.inventory')} />
                            <SemanticDropdown
                                fluid
                                search
                                selection
                                disabled={edit || inventoriesOptions.length === 1}
                                placeholder={
                                    inventories?.length === 0
                                        ? t('partners.invitation.attendees_quickadd.placeholder_no_invitations')
                                        : t('partners.invitation.creation.columns.header.inventory')
                                }
                                options={inventoriesOptions}
                                onChange={(e, { value }) => {
                                    let inventory = inventoriesOptions.find((inv) => inv.value === value).inventory;
                                    onChange(inventory, null, null, null);
                                }}
                                value={inventory?.uuid}
                            />
                        </div>
                        {inventory && (
                            <div className={Styles['seating-input']}>
                                <Form.Label label={t('partners.invitation.creation.columns.header.rate')} />
                                <SemanticDropdown
                                    fluid
                                    search
                                    selection
                                    disabled={edit || (ratesOptions.length === 1 && rateSelection.rate)}
                                    placeholder={
                                        inventories?.length === 0
                                            ? t('partners.invitation.attendees_quickadd.placeholder_no_invitations')
                                            : t('partners.invitation.creation.columns.header.rate')
                                    }
                                    options={ratesOptions}
                                    onChange={(event, { value }) => {
                                        let rate = ratesOptions.find((rate) => rate.value === value).rate;
                                        onChange(inventory, rate, null, null);
                                    }}
                                    value={rate?.id}
                                />
                            </div>
                        )}
                    </div>
                    <FirstBooking
                        edit={edit}
                        onChange={onChange}
                        inventory={inventory}
                        rate={rate}
                        rateSelection={rateSelection}
                        zonesOptions={zonesOptions}
                        seatsOptions={seatsOptions}
                        displayFirstBooking={displayFirstBooking}
                        isManual={isManual}
                    />
                    <EditSeating
                        edit={edit}
                        onChange={onChange}
                        inventory={inventory}
                        rate={rate}
                        rateSelection={rateSelection}
                        zonesOptions={zonesOptions}
                        seatsOptions={seatsOptions}
                    />
                    {maxAvailableReached && !isManual && (
                        <Form.FieldErrors style={{ marginBottom: '15px' }} errors={[t('partners.invitation.table.side_menu.attendee.too_much_attendees_event')]} />
                    )}
                </Form>
            </ContentCard>
        </Card>
    );
}
SeatingInformationCard.propTypes = {
    partnerId: PropTypes.string.isRequired,
    rateModel: PropTypes.any.isRequired,
    bookingModel: PropTypes.any.isRequired
};
