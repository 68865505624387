import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { Icon } from '@weezevent/nacre';

import Styles from '../../styles/components/portal.module.css';

function CloseButton({ onClick = () => {} }) {
    return <Icon name="cross" onClick={onClick} className={Styles['close']} />;
}

CloseButton.propTypes = {
    onClick: PropTypes.func
};

export function Portal({ children, size = 'medium', className = 'root-portal', open = true, depth = 0, onClickAway, showCloseButton = true, onClose = () => {} }) {
    const [isOpen, setOpen] = React.useState(false);
    const [_class, setClass] = React.useState('');
    const ref = React.useRef(null);
    const refClick = React.useRef(null);
    const sizes = {
        small: '550px',
        medium: '650px',
        large: '800px'
    };

    const handleClickOutside = React.useCallback(
        (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickAway();
            }
        },
        [onClickAway]
    );

    React.useEffect(() => {
        if (!isOpen && open) {
            setClass(Styles['portal-in']);
            refClick.current = handleClickOutside;
            document.addEventListener('click', refClick.current);
            return setOpen(true);
        }

        // State changed
        if (isOpen && !open) {
            // Activate animation
            setClass(Styles['portal-out']);
            document.removeEventListener('click', refClick.current);
            // MUST be the same or lower as the animation
            setTimeout(() => {
                setClass('');
                setOpen(false);
            }, 300);
        }
    }, [open, isOpen, onClickAway, handleClickOutside]);

    React.useEffect(() => {
        if (size in sizes && ref.current) {
            ref.current.style.setProperty('--drawer-size', sizes[size]);
        }
    }, [size, sizes, ref]);

    if (!isOpen) {
        return null;
    }

    return ReactDom.createPortal(
        <div ref={ref} className={`socium-drawer ${Styles['portal']} ${className} ${_class} depth-${depth} ${Styles[`portal_size-${size}`]}`}>
            {showCloseButton && <CloseButton onClick={onClose} />}
            {children}
        </div>,
        window.document.body
    );
}

Portal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    element: PropTypes.string,
    open: PropTypes.bool,
    size: PropTypes.string,
    onClickAway: PropTypes.func,
    showCloseButton: PropTypes.bool,
    onClose: PropTypes.func
};

export default Portal;
