import React from 'react';
import PropTypes from 'prop-types';

import Style from './infoTextTooltip.module.css';
import { Tooltip, InfoText } from '@weezevent/nacre';

export function InfoTextTooltip({ title, subtitle = null, className = '' }) {
    const tooltipTitle = React.useMemo(() => {
        if (subtitle) {
            return `${title} \n${subtitle}`;
        }

        return title;
    }, [title, subtitle]);

    return (
        <InfoText className={`${Style['infotext-tooltip']} ${className}`} subtitle={subtitle}>
            <Tooltip triggerOnHover position={'top-start'} title={<span className={Style['infotext-tooltip__span-tooltip']}>{tooltipTitle}</span>} margin={false}>
                <span>{title}</span>
            </Tooltip>
        </InfoText>
    );
}

InfoTextTooltip.propTypes = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    className: PropTypes.string
};
