import React from 'react';
import PropTypes from 'prop-types';
import SideBar from '../invitations/portal';
import { StandardHeader } from '@weezevent/nacre';
import Styles from '../../styles/components/stats/stats-sidebar-table.module.css';
import { useTranslation } from 'react-i18next';
import { TableQuotasCard } from './tableQuotasCard';

export default function StatsSideBar({ showSideBar, depth = 0, datas, onClickAway = () => {} }) {
    const { t } = useTranslation();
    const [inventories, setInventories] = React.useState([]);

    React.useEffect(() => {
        // Ordering of iventories ( events ) before to display them.
        const sortedActivities = datas.sort((a, b) => new Date(b.event?.end_date) - new Date(a.event?.end_date));
        setInventories(sortedActivities);
    }, [datas, setInventories]);

    return (
        <SideBar className={Styles['container']} open={showSideBar} depth={depth} onClickAway={onClickAway} onClose={onClickAway}>
            <StandardHeader title={t('partners.invitation.table.side_menu.stats.title')} />
            <TableQuotasCard datas={inventories} />
        </SideBar>
    );
}

StatsSideBar.propTypes = {
    showSideBar: PropTypes.bool,
    depth: PropTypes.number,
    datas: PropTypes.array.isRequired,
    onClickAway: PropTypes.func
};
